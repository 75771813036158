import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class AsnOrders_ds_get_planned_receiving_tasks_grouped_by_orderId_shipmentIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number, shipmentId: number }): 
  Promise<{ result: { TotalExpectedAmount?: number, TotalExpectedPackagedAmount?: number, OrderId?: number, ShipmentId?: number, LotId?: number, MaterialId?: number, ExpectedInventoryAmountPackId?: number, ExpectedPackagedPackId?: number, VendorLotId?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, shipmentId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { TotalExpectedAmount?: number, TotalExpectedPackagedAmount?: number, OrderId?: number, ShipmentId?: number, LotId?: number, MaterialId?: number, ExpectedInventoryAmountPackId?: number, ExpectedPackagedPackId?: number, VendorLotId?: number }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, shipmentId: number, $keys: { OrderId?: number, ShipmentId?: number, LotId?: number, MaterialId?: number, ExpectedInventoryAmountPackId?: number, ExpectedPackagedPackId?: number, VendorLotId?: number }[] }): 
  Promise<{ result: { TotalExpectedAmount?: number, TotalExpectedPackagedAmount?: number, OrderId?: number, ShipmentId?: number, LotId?: number, MaterialId?: number, ExpectedInventoryAmountPackId?: number, ExpectedPackagedPackId?: number, VendorLotId?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
