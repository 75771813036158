import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class PackVerification_ds_get_picking_tasks_by_licenseplateIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { licenseplateId: number, lotId?: number, packagingId?: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, ActualSourceLicensePlateId?: number, ActualTargetLicensePlateId?: number, ChainHead?: number, OrderId?: number, OrderLineNumber?: number, PickSlipId?: number, ProjectId?: number, ShipmentId?: number, ShipmentLineId?: number, StatusId?: number, WarehouseId?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.licenseplateId)) {
      missingRequiredInParams.push('\'licenseplateId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { licenseplateId: number, lotId?: number, packagingId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, ActualSourceLicensePlateId?: number, ActualTargetLicensePlateId?: number, ChainHead?: number, OrderId?: number, OrderLineNumber?: number, PickSlipId?: number, ProjectId?: number, ShipmentId?: number, ShipmentLineId?: number, StatusId?: number, WarehouseId?: number }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.licenseplateId)) {
      missingRequiredInParams.push('\'licenseplateId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { licenseplateId: number, lotId?: number, packagingId?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, ActualSourceLicensePlateId?: number, ActualTargetLicensePlateId?: number, ChainHead?: number, OrderId?: number, OrderLineNumber?: number, PickSlipId?: number, ProjectId?: number, ShipmentId?: number, ShipmentLineId?: number, StatusId?: number, WarehouseId?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.licenseplateId)) {
      missingRequiredInParams.push('\'licenseplateId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
