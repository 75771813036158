import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class WarehouseTransfers_ds_warehouse_transfers_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { warehouseIds?: number[], statusIds?: number[], fullTextSearch?: string }): 
  Promise<{ result: { Id?: number, DateCreated?: string, LookupCode?: string, Project?: { Id?: number, LookupCode?: string, Owner?: { Id?: number, LookupCode?: string } }, SourceOutboundOrder?: { Id?: number, LookupCode?: string }, TargetInboundOrder?: { Id?: number, LookupCode?: string }, SourceWarehouse?: { Id?: number, Name?: string }, TargetWarehouse?: { Id?: number, Name?: string }, Status?: { Id?: number, Name?: string } }[] }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { warehouseIds?: number[], statusIds?: number[], fullTextSearch?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, DateCreated?: string, LookupCode?: string, Project?: { Id?: number, LookupCode?: string, Owner?: { Id?: number, LookupCode?: string } }, SourceOutboundOrder?: { Id?: number, LookupCode?: string }, TargetInboundOrder?: { Id?: number, LookupCode?: string }, SourceWarehouse?: { Id?: number, Name?: string }, TargetWarehouse?: { Id?: number, Name?: string }, Status?: { Id?: number, Name?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { warehouseIds?: number[], statusIds?: number[], fullTextSearch?: string, $keys: number[] }): 
  Promise<{ result: { Id?: number, DateCreated?: string, LookupCode?: string, Project?: { Id?: number, LookupCode?: string, Owner?: { Id?: number, LookupCode?: string } }, SourceOutboundOrder?: { Id?: number, LookupCode?: string }, TargetInboundOrder?: { Id?: number, LookupCode?: string }, SourceWarehouse?: { Id?: number, Name?: string }, TargetWarehouse?: { Id?: number, Name?: string }, Status?: { Id?: number, Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
