import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_label_mckcvsServiceInParams {
  shippingContainerId: number}

interface IReports_custom_label_mckcvsServiceData {
  label?: { result?: { Id?: number, LookupCode?: string, Shipment?: { LookupCode?: string, OrderLookups?: { OrderId?: number, ShipmentId?: number, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string, Project?: { Id?: number, LookupCode?: string, Name?: string }, PreferredWarehouse?: { Name?: string, WarehousesContactsLookup?: { ContactId?: number, Contact?: { PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } }[], Carrier?: { Name?: string, ScacCode?: string, ShortName?: string }, CarrierServiceType?: { Name?: string, ShortName?: string } }, ContainerType?: { Description?: string, Name?: string }, deliver_to?: { Id?: number, AlternateFax?: string, AlternateTelephone?: string, AttentionOf?: string, City?: string, Country?: string, FirstName?: string, Greeting?: string, LastName?: string, Line1?: string, Line2?: string, Line3?: string, Name?: string, PostalCode?: string, PrimaryFax?: string, PrimaryTelephone?: string, State?: string, Title?: string }, first7?: string, ord4char?: string } }
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_label_mckcvsService extends ReportBaseService<IReports_custom_label_mckcvsServiceInParams, IReports_custom_label_mckcvsServiceData> {

  protected reportReferenceName = 'custom_label_mckcvs';
  protected appReferenceName = 'Reports';

  constructor(
    utils: UtilsService,
    private datasources: Reports_DatasourceService,
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_label_mckcvsServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_label_mckcvsServiceInParams): Promise<IReports_custom_label_mckcvsServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IReports_custom_label_mckcvsServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_label_mckcvsServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        shippingContainerId:  $report.inParams.shippingContainerId 
      };
      
      const dsData = await this.datasources.Reports.custom_ds_outbound_label_by_shippingContainerId.get(dsParams);
      
      data.label = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
