import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class PackVerification_ds_get_shippingcontainers_by_warehouseIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { warehouseId: number, locationId?: number, ownerId?: number, projectId?: number, shippingDate?: string, shippingContainerCode?: string, fullTextSearch?: string }): 
  Promise<{ result: { Id?: number, LookupCode?: string, Notes?: string, ShippingDate?: string, Shipment?: { ActualWarehouseId?: number, ExpectedDate?: string, Carrier?: { Name?: string }, CarrierServiceType?: { Name?: string }, OrderLookups?: { CreatedSysDateTime?: string, Order?: { LookupCode?: string, OwnerReference?: string, RequestedDeliveryDate?: string, PreferredCarrier?: { Name?: string }, PreferredCarrierServiceType?: { Name?: string }, Project?: { LookupCode?: string, Owner?: { LookupCode?: string } } } }[] }, TasksForActualSourceShippingContainer?: { Id?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, LotId?: number, MaterialId?: number }[], LicensePlates?: { Id?: number, LicensePlateContents?: { PackagedId?: number, PackagedAmount?: number, Lot?: { Id?: number, MaterialId?: number } }[], Location?: { Name?: string } }[], ContainerType?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { warehouseId: number, locationId?: number, ownerId?: number, projectId?: number, shippingDate?: string, shippingContainerCode?: string, fullTextSearch?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, LookupCode?: string, Notes?: string, ShippingDate?: string, Shipment?: { ActualWarehouseId?: number, ExpectedDate?: string, Carrier?: { Name?: string }, CarrierServiceType?: { Name?: string }, OrderLookups?: { CreatedSysDateTime?: string, Order?: { LookupCode?: string, OwnerReference?: string, RequestedDeliveryDate?: string, PreferredCarrier?: { Name?: string }, PreferredCarrierServiceType?: { Name?: string }, Project?: { LookupCode?: string, Owner?: { LookupCode?: string } } } }[] }, TasksForActualSourceShippingContainer?: { Id?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, LotId?: number, MaterialId?: number }[], LicensePlates?: { Id?: number, LicensePlateContents?: { PackagedId?: number, PackagedAmount?: number, Lot?: { Id?: number, MaterialId?: number } }[], Location?: { Name?: string } }[], ContainerType?: { Name?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { warehouseId: number, locationId?: number, ownerId?: number, projectId?: number, shippingDate?: string, shippingContainerCode?: string, fullTextSearch?: string, $keys: number[] }): 
  Promise<{ result: { Id?: number, LookupCode?: string, Notes?: string, ShippingDate?: string, Shipment?: { ActualWarehouseId?: number, ExpectedDate?: string, Carrier?: { Name?: string }, CarrierServiceType?: { Name?: string }, OrderLookups?: { CreatedSysDateTime?: string, Order?: { LookupCode?: string, OwnerReference?: string, RequestedDeliveryDate?: string, PreferredCarrier?: { Name?: string }, PreferredCarrierServiceType?: { Name?: string }, Project?: { LookupCode?: string, Owner?: { LookupCode?: string } } } }[] }, TasksForActualSourceShippingContainer?: { Id?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, LotId?: number, MaterialId?: number }[], LicensePlates?: { Id?: number, LicensePlateContents?: { PackagedId?: number, PackagedAmount?: number, Lot?: { Id?: number, MaterialId?: number } }[], Location?: { Name?: string } }[], ContainerType?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
