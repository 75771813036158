import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Returns_ds_get_order_addresses_by_orderIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number }): 
  Promise<{ result: { Id?: number, AlternateEmail?: string, AlternateFax?: string, AlternateTelephone?: string, AttentionOf?: string, City?: string, Country?: string, FirstName?: string, Greeting?: string, IsResidential?: boolean, LastName?: string, Line1?: string, Line2?: string, Line3?: string, Line4?: string, LookupCode?: string, MiddleName?: string, Name?: string, Notes?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryFax?: string, PrimaryTelephone?: string, ReferenceCode?: string, State?: string, Title?: string, TypeId?: number, Url?: string }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, AlternateEmail?: string, AlternateFax?: string, AlternateTelephone?: string, AttentionOf?: string, City?: string, Country?: string, FirstName?: string, Greeting?: string, IsResidential?: boolean, LastName?: string, Line1?: string, Line2?: string, Line3?: string, Line4?: string, LookupCode?: string, MiddleName?: string, Name?: string, Notes?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryFax?: string, PrimaryTelephone?: string, ReferenceCode?: string, State?: string, Title?: string, TypeId?: number, Url?: string }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, AlternateEmail?: string, AlternateFax?: string, AlternateTelephone?: string, AttentionOf?: string, City?: string, Country?: string, FirstName?: string, Greeting?: string, IsResidential?: boolean, LastName?: string, Line1?: string, Line2?: string, Line3?: string, Line4?: string, LookupCode?: string, MiddleName?: string, Name?: string, Notes?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryFax?: string, PrimaryTelephone?: string, ReferenceCode?: string, State?: string, Title?: string, TypeId?: number, Url?: string }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
