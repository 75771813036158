import { Inject, Injectable, Injector }from '@angular/core';


import { PrintNode_ds_get_printersService } from './PrintNode.datasource.index';
import { PrintNode_ds_get_printjobsService } from './PrintNode.datasource.index';

@Injectable({ providedIn: 'root' })
export class PrintNode_DatasourceService {

  constructor(
    private injector: Injector
  ) {
  }

  public PrintNode: PrintNode_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_printers: PrintNode_ds_get_printersService;
  public get ds_get_printers(): PrintNode_ds_get_printersService {
    if(!this._ds_get_printers) {
      this._ds_get_printers = this.injector.get(PrintNode_ds_get_printersService);
    }
    return this._ds_get_printers;
  }
  private _ds_get_printjobs: PrintNode_ds_get_printjobsService;
  public get ds_get_printjobs(): PrintNode_ds_get_printjobsService {
    if(!this._ds_get_printjobs) {
      this._ds_get_printjobs = this.injector.get(PrintNode_ds_get_printjobsService);
    }
    return this._ds_get_printjobs;
  }
}

