import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class AsnOrders_ds_asn_order_licenseplates_receiving_tasks_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { licenseplateId?: number, orderId: number, fullTextSearch?: string }): 
  Promise<{ result: { Id?: number, ExpectedInventoryAmount?: number, ExpectedPackagedAmount?: number, OrderId?: number, OrderLineNumber?: number, ProjectId?: number, ShipmentLineId?: number, StatusId?: number, Material?: { Id?: number, ControllerTypeId?: number, LookupCode?: string, PackagingLookups?: { PackagingId?: number }[] }, VendorLot?: { Id?: number, LookupCode?: string }, Lot?: { Id?: number, LookupCode?: string }, ExpectedPackagedPack?: { Id?: number, Name?: string, ShortName?: string }, Status?: { Name?: string }, ActualTargetLicensePlate?: { Id?: number, Archived?: boolean, LookupCode?: string, LicensePlateContents?: { LicensePlateId?: number, LotId?: number, PackagedId?: number, Amount?: number, PackagedAmount?: number }[], Location?: { Name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { licenseplateId?: number, orderId: number, fullTextSearch?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ExpectedInventoryAmount?: number, ExpectedPackagedAmount?: number, OrderId?: number, OrderLineNumber?: number, ProjectId?: number, ShipmentLineId?: number, StatusId?: number, Material?: { Id?: number, ControllerTypeId?: number, LookupCode?: string, PackagingLookups?: { PackagingId?: number }[] }, VendorLot?: { Id?: number, LookupCode?: string }, Lot?: { Id?: number, LookupCode?: string }, ExpectedPackagedPack?: { Id?: number, Name?: string, ShortName?: string }, Status?: { Name?: string }, ActualTargetLicensePlate?: { Id?: number, Archived?: boolean, LookupCode?: string, LicensePlateContents?: { LicensePlateId?: number, LotId?: number, PackagedId?: number, Amount?: number, PackagedAmount?: number }[], Location?: { Name?: string } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { licenseplateId?: number, orderId: number, fullTextSearch?: string, $keys: number[] }): 
  Promise<{ result: { Id?: number, ExpectedInventoryAmount?: number, ExpectedPackagedAmount?: number, OrderId?: number, OrderLineNumber?: number, ProjectId?: number, ShipmentLineId?: number, StatusId?: number, Material?: { Id?: number, ControllerTypeId?: number, LookupCode?: string, PackagingLookups?: { PackagingId?: number }[] }, VendorLot?: { Id?: number, LookupCode?: string }, Lot?: { Id?: number, LookupCode?: string }, ExpectedPackagedPack?: { Id?: number, Name?: string, ShortName?: string }, Status?: { Name?: string }, ActualTargetLicensePlate?: { Id?: number, Archived?: boolean, LookupCode?: string, LicensePlateContents?: { LicensePlateId?: number, LotId?: number, PackagedId?: number, Amount?: number, PackagedAmount?: number }[], Location?: { Name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
