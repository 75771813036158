import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class SalesOrders_ds_get_order_total_picked_units_by_variousService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], carrierId?: number, carrierServiceId?: number, fromDate?: string, toDate?: string, waveId?: number }): 
  Promise<{ result: { TotalUnitsPicked?: number, ShipmentLine?: { OrderId?: number, OrderLine?: { Order?: { ProjectId?: number, PreferredWarehouseId?: number, OrderStatusId?: number, PreferredCarrierId?: number, PreferredCarrierServiceTypeId?: number, CreatedSysDateTime?: string, OrderClass?: { OrderClassTypeId?: number }, Project?: { OwnerId?: number } } } }, orderTotals?: { TotalUnits?: number, TotalPrice?: number, OrderId?: number } }[] }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], carrierId?: number, carrierServiceId?: number, fromDate?: string, toDate?: string, waveId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { TotalUnitsPicked?: number, ShipmentLine?: { OrderId?: number, OrderLine?: { Order?: { ProjectId?: number, PreferredWarehouseId?: number, OrderStatusId?: number, PreferredCarrierId?: number, PreferredCarrierServiceTypeId?: number, CreatedSysDateTime?: string, OrderClass?: { OrderClassTypeId?: number }, Project?: { OwnerId?: number } } } }, orderTotals?: { TotalUnits?: number, TotalPrice?: number, OrderId?: number } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], carrierId?: number, carrierServiceId?: number, fromDate?: string, toDate?: string, waveId?: number, $keys: { ShipmentLine?: { OrderLine?: { Order?: { OrderClass?: { OrderClassTypeId?: number }, Project?: { OwnerId?: number }, ProjectId?: number, PreferredWarehouseId?: number, OrderStatusId?: number, PreferredCarrierId?: number, PreferredCarrierServiceTypeId?: number, CreatedSysDateTime?: string } }, OrderId?: number } }[] }): 
  Promise<{ result: { TotalUnitsPicked?: number, ShipmentLine?: { OrderId?: number, OrderLine?: { Order?: { ProjectId?: number, PreferredWarehouseId?: number, OrderStatusId?: number, PreferredCarrierId?: number, PreferredCarrierServiceTypeId?: number, CreatedSysDateTime?: string, OrderClass?: { OrderClassTypeId?: number }, Project?: { OwnerId?: number } } } }, orderTotals?: { TotalUnits?: number, TotalPrice?: number, OrderId?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
